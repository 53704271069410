<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            8. Нормы обеспечения учреждений средствами пожаротушения
          </v-card-title>
        </v-col>
        <v-col cols="6">

          <v-card-title >
            Руководитель организации обеспечивает<br> объект защиты:
          </v-card-title>
          <v-card-text class="title font-weight-light pb-0">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            огнетушителями по нормам, установленным Правилами противопожарного режима в РФ;
          </v-card-text>
          <v-card-text class="title font-weight-light pb-0">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            соблюдение сроков перезарядки, освидетельствования и своевременной замены, указанных в паспорте огнетушителя.
          </v-card-text>

        </v-col>

        <v-col cols="6">
          <v-dialog
            v-model="dialog1"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                height="50"
                color="red"
                class="mb-3"
                v-on="on"
                v-bind="attrs"
                block
                >
                  Нормы обеспечения огнетушителями
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline">
                Нормы обеспечения огнетушителями
              </v-card-title>
              <v-card-text class="title font-weight-light">
                Нормы обеспечения огнетушителями объектов защиты в зависимости от их категорий по пожарной и взрывопожарной опасности и класса пожара (за исключением автозаправочных станций и железнодорожного подвижного состава)
              </v-card-text>
                <v-simple-table  class="ptm-table elevation-1  ma-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th dark class="red white--text text-center title">
                          Категории помещения
                        </th>
                        <th class="red white--text text-center title">
                          Класс пожара
                        </th>
                        <th class="red white--text text-center title">
                          Огнетушители с рангом тушения модельного очага
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in table1" :key="index">
                        <td style="border-right:1px solid #ccc" class="title text-center font-weight-light">{{ item.category }}</td>
                        <td style="border-right:1px solid #ccc" class="text-center title font-weight-light" v-html="item.class"></td>
                        <td style="border-right:1px solid #ccc" class="title text-center font-weight-light" v-html="item.capacity"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialog2"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                height="50"
                color="red"
                class="mb-3"
                v-on="on"
                v-bind="attrs"
                block
                >
                  Нормы оснащения помещений<br> передвижными огнетушителями
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline">
                Нормы оснащения помещений передвижными огнетушителями
              </v-card-title>
              <v-card-text class="title font-weight-light">
                Нормы оснащения помещений передвижными огнетушителями (за исключением автозаправочных станций и железнодорожного подвижного состава)
              </v-card-text>
                <v-simple-table  class="ptm-table elevation-1  ma-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th dark class="red white--text text-center title">
                          Категории помещения
                        </th>
                        <th class="red white--text text-center title">
                          Предельная защищаемая площадь м<sup>2</sup>
                        </th>
                        <th class="red white--text text-center title">
                          Класс пожара
                        </th>
                        <th class="red white--text text-center title">
                          Огнетушители с рангом тушения модельного очага (штук)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in table2" :key="index">
                        <td style="border-right:1px solid #ccc" class="title text-center font-weight-light">{{ item.category }}</td>
                        <td style="border-right:1px solid #ccc" class="text-center title font-weight-light" v-html="item.pl"></td>
                        <td style="border-right:1px solid #ccc" class="text-center title font-weight-light" v-html="item.class"></td>
                        <td style="border-right:1px solid #ccc" class="title text-center font-weight-light" v-html="item.capacity"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-text class="title font-weight-light">
                  <p>Здания и сооружения производственного и складского назначения площадью более 500м2 дополнительно оснащаются передвижными огнетушителями.</p>
                  <p><strong>Не требуется оснащение передвижными огнетушителями зданий и сооружений категории Д.</strong></p>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialog3"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                height="50"
                color="red"
                class="mb-3"
                v-on="on"
                v-bind="attrs"
                block
                >
                  Расстояния до места размещения огнетушителя
              </v-btn>
            </template>
            <v-card class="pa-5 noselect" >
              <v-card-title class="headline">
                Расстояния до места размещения огнетушителя
              </v-card-title>
              <v-card-text class="title font-weight-light">
                В общественных зданиях и сооружениях на каждом этаже размещается не менее 2 огнетушителей.
              </v-card-text>
              <v-card-text class="title font-weight-light">
                Помещения, оборудованные автоматическими установками пожаротушения, обеспечиваются огнетушителями на 50% от расчетного количества огнетушителей.
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <span class="headline red--text">Расстояние от возможного очага пожара до места размещения переносного огнетушителя не должно превышать:</span><br> <small>(с учетом перегородок, дверных проемов, загромождений, оборудования)</small>
              </v-card-text>
              <v-card-text class="title font-weight-bold">
                <span class="headline red--text">20м</span> - для помещений административного и общественного назначения;
              </v-card-text>
              <v-card-text class="title font-weight-bold">
                <span class="headline red--text">30м</span> - для помещений категории: повышенная взрывопожароопасность (А); взрывопожароопасность (Б); пожароопасность (В1-В4);
              </v-card-text>
              <v-card-text class="title font-weight-bold">
                <span class="headline red--text">40м</span> - для помещений категории умеренной пожароопасности (Г);
              </v-card-text>
              <v-card-text class="title font-weight-bold">
                <span class="headline red--text">70м</span> - для помещений категории пониженной пожароопасности (Д).
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Hint></Hint>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-5">
            <v-card-title >
            При определении видов и количества первичных средств пожаротушения учитывают:
          </v-card-title>
          <v-row>
            <v-col cols="7">
              <v-card
              class="pa-2 d-flex justify-center align-center"
              flat

              >
                <v-img
                  lazy-src="img/material/flask.svg"
                  max-width="70"
                  src="img/material/flask.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  физико-химические и пожароопасные свойства горючих веществ, их взаимодействие с огнетушащими веществами
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5"  class="d-flex justify-center align-center">
              <v-card
              class="pa-2 d-flex justify-center align-center"
              full-width
              flat

              >
                <v-img
                  lazy-src="img/material/power-plant.svg"
                  max-width="80"
                  src="img/material/power-plant.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  площадь помещений, открытых площадок и установок
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card-text class="title font-weight-light">
            <p>Комплектование технологического оборудования огнетушителями осуществляется согласно требованиям технических условий (паспортов) на это оборудование.</p>
            <p>Комплектование импортного оборудования производится согласно условиям договора на его поставку.</p>
            <p>Учет наличия, периодичности осмотра и сроков перезарядки огнетушителей ведется в журнале эксплуатации систем противопожарной защиты.</p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    table1: [
      {
        category: 'A, Б, В1 - В4',
        class: 'А<br>B<br>C<br>D<br>E',
        capacity: '4А<br>144B<br>4A, 144B, C или 144B, C<br>D<br>55B, C, E'
      },
      {
        category: 'Г, Д',
        class: 'А<br>B<br>C<br>D<br>E',
        capacity: '2А<br>55B<br>2A, 55B, C или 55B, C<br>D<br>55B, C, E'
      },
      {
        category: 'Общественные здания',
        class: 'А<br>B<br>C<br>E',
        capacity: '2А<br>55B<br>2A, 55B, C или 55B, C<br>55B, C, E'
      }
    ],
    table2: [
      {
        category: 'A, Б, В1 - В4',
        pl: '500',
        class: 'А<br>B<br>C<br>D<br>E',
        capacity: '2 - 6А или 1 - 10А<br>2 - 144B или 1 - 123B<br>2 - 6A, 144B, C или 10A, 233B, C<br>1- D<br>2- 6A, 144B, C, E или 1 - 10A, 233B, C, E'
      },
      {
        category: 'Г, Д',
        pl: '800',
        class: 'А<br>B<br>C<br><br>D<br>E',
        capacity: '2 - 6А или 1 - 10А<br>2 - 144B или 1 - 123B<br>2 - 6A, 144B, C или 10A, 233B, C<br>или 2 - 144B, C или 1 - 123B, C<br>1- D<br>2- 6A, 144B, C, E или 1 - 10A, 233B, C, E<br>или 2 - 144B, C, E или 1 233B, C, E'
      }
    ]
  }),
  components: {
    Hint
  }
}
</script>
